<template>
  <b-card v-if="loading">
    <div
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
  </b-card>

  <div v-else>
    <Form
      :orders-data.sync="formData"
      :all-companies="allCompanies"
      :all-users="allUsers"
      @formSubmit="doFormSave"
    /></div>
</template>

<script>
// import axiosIns from '@/libs/axios'
import { BCard, BSpinner } from 'bootstrap-vue'
import store from '@/store'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import _ from 'lodash'
import router from '@/router'
import Form from './Form.vue'

// eslint-disable-next-line no-unused-vars
const emptyFormData = Object.freeze({
  organization_id: null,
  full_name: '',
  phone: '',
  auto_info_vin: '',
  auto_info_gos_nomer: '',
  auto_info_mark: '',
  auto_info_model: '',
  auto_info_year: null,
  auto_info_milage: '',
  note: '',

  sts_pts_take: 'да',
  passport_take: 'нет',
  serviseBook_take: 'нет',
  doverennost_take: 'нет',
  key_take: 'да',
  list_materials_take: 'нет',

  osmotr_auto: true,
  spare_parts: false,
  phone_aprove: true,
  personal_aprove: true,
  max_sum_aprove: null,

  type_pay: null,
  sum: null,
  percent_beznal: 8.6,
  status_order: 1,

  works_data: {
    items: Object.freeze([]),
    nextTodoId: 1,
  },
  parts_data: {
    items: Object.freeze([]),
    nextTodoId: 1,
  },

  recomended: '',
  executor: null,
  warranty_period: 1,
  warranty_period_parts: null,
})

export default {
  name: 'OrdersCreate',
  components: {
    BCard,
    BSpinner,
    Form,
  },
  data() {
    return {
      formData: JSON.parse(JSON.stringify(emptyFormData)),
      ordersData: null,
      allCompanies: null,
      allUsers: null,
      loading: false,
    }
  },
  mounted() {
    Promise.all([
      this.loadCurrentOrder(this.$route.params.id),
      this.loadAllCompanies(),
      this.loadAllUsers(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    doFormSave(params) {
      axiosIns({
        method: 'POST',
        url: `/orders/${this.$route.params.id}`,
        data: this.formData,
        // eslint-disable-next-line no-unused-vars
      }).then(response => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Заказ',
            icon: 'CartIcon',
            variant: 'success',
            text: 'Заказ сохранен!',
          },
        })

        if (params === 'save' && this.formData.status_order === 3) {
          router.push({ name: 'orders' })
        }

        if (params === 'akt') {
          axiosIns({
            method: 'GET',
            responseType: 'blob',
            url: `/orders/${this.$route.params.id}/akt`,
            data: this.formData,
            // eslint-disable-next-line no-shadow
          }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        }
        if (params === 'akt_agregat') {
          axiosIns({
            method: 'GET',
            responseType: 'blob',
            url: `/orders/${this.$route.params.id}/akt_agregat`,
            data: this.formData,
            // eslint-disable-next-line no-shadow
          }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        }
        if (params === 'akt_work') {
          axiosIns({
            method: 'GET',
            responseType: 'blob',
            url: `/orders/${this.$route.params.id}/akt_work`,
            data: this.formData,
            // eslint-disable-next-line no-shadow
          }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        }
        if (params === 'naryad') {
          axiosIns({
            method: 'GET',
            responseType: 'blob',
            url: `/orders/${this.$route.params.id}/naryad`,
            data: this.formData,
            // eslint-disable-next-line no-unused-vars,no-shadow
          }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        }
        if (params === 'osmotr') {
          axiosIns({
            method: 'GET',
            responseType: 'blob',
            url: `/orders/${this.$route.params.id}/osmotr`,
            data: this.formData,
            // eslint-disable-next-line no-unused-vars,no-shadow
          }).then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        }
      })
    },
    loadCurrentOrder(id) {
      return axiosIns({
        method: 'GET',
        url: `/orders/${id}`,
      })
        .then(response => {
          this.$set(this, 'formData', _.merge(JSON.parse(JSON.stringify(emptyFormData)), response.data.data))
        })
    },
    loadAllCompanies() {
      const req = axiosIns({
        method: 'GET',
        url: '/organizations/short-list',
      })

      req.then(response => {
        const defaultCompanies = [{ id: null, name: ' ---------- ' }]
        defaultCompanies.push(...response.data.data)
        this.allCompanies = defaultCompanies
      })

      return req
    },
    loadAllUsers() {
      const req = axiosIns({
        method: 'GET',
        url: '/users/short-list?without=prog,admin',
      })

      req.then(response => {
        const defaultUsers = [{ id: null, full_name: ' ---------- ' }]
        defaultUsers.push(...response.data.data)
        this.allUsers = defaultUsers
      })

      return req
    },
  },
}
</script>
